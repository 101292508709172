import type { ComponentType, PropsWithChildren } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let global: any;

export const registerComponent = (name: string, component: ComponentType<PropsWithChildren>) => {
    if (!global.__react) {
        global.__react = {};
    }

    global.__react[name] = component;
};
